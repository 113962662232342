import axios from 'axios'

const endpoint = '/api/products'

export default {
  get: payload => axios.post(endpoint, { page: payload.page, paginate: payload.paginate }, { headers: { 'X-Page-Size': payload.perPage } }),
  set: payload => axios.post(`${endpoint}/save`, payload),
  delete: payload => axios.delete(`${endpoint}/delete-product/${payload}`),
  getById: payload => axios.get(`${endpoint}/${payload.productId}`),
  // getById: payload => axios.post(`${endpoint}/${payload.productId}`, { productId: payload.productId }),
  setDependency: payload => axios.post(`${endpoint}/dependency/save`, payload),
  getProductDependencies: payload => axios.get(`${endpoint}/dependencies/${payload}`),
  deleteProductDependencies: payload => axios.delete(`${endpoint}/dependency/${payload}`),
  deleteImageProduct: payload => axios.delete(`${endpoint}/delete-image/${payload}`),
  getProductMax: () => axios.get(`${endpoint}/max`),
  search: payload => axios.post(`${endpoint}/search-products`, {
    word: payload.word,
    paginate: payload.paginate,
    perPage: payload.perPage,
    page: payload.page,
  }),
}
