export default [
  {
    path: '/budgets/list',
    name: 'drs-budgets',
    component: () => import('@/views/drs/budgets/budgets-list/BudgetList.vue'),
    meta: {
      pageTitle: 'menu.budgets',
      breadcrumb: [
        {
          text: 'menu.budgets',
        },
        {
          text: 'common.list',
          active: true,
        },
      ],
    },
  },
  {
    path: '/budgets/edit/:id',
    name: 'drs-budgets-form',
    component: () => import('@/views/drs/budgets/BudgetForm.vue'),
    meta: {
      pageTitle: 'menu.budgets',
      breadcrumb: [
        {
          text: 'menu.budgets',
          to: '/budgets/list',
        },
        {
          text: 'common.form',
          active: true,
        },
      ],
    },
  },
  {
    path: '/budgets/preview/:id',
    name: 'drs-budgets-preview',
    meta: {
      pageTitle: 'menu.preview',
      breadcrumb: [
        {
          text: 'menu.budgets',
          to: '/budgets/list',
        },
        {
          text: 'menu.preview',
          active: true,
        },
      ],
    },
    component: () => import('@/views/drs/budgets/budgets-preview/BudgetPreview.vue'),
  },
  // {
  //   path: '/budgets/accept',
  //   name: 'drs-accept-budget',
  //   component: () => import('@/views/drs/invoices/AcceptInvoice.vue'),
  //   meta: {
  //     layout: 'full',
  //   },
  // },
  {
    path: '/budgets',
    name: 'drs-budget-view',
    component: () => import('@/views/drs/budgets/BudgetView.vue'),
    meta: {
      layout: 'full',
    },
  },
]
