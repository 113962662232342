import axios from 'axios'

const endpoint = '/api/finances'
const endpointBestSelling = '/api/finances/best-selling-products'

export default {
  getAll: payload => axios.post(endpoint, { page: payload.page, paginate: payload.paginate, word: payload.word }, { headers: { 'X-Page-Size': payload.perPage } }),
  get: payload => axios.get(`${endpoint}/${payload}`),
  save: payload => axios.post(`${endpoint}/save`, payload),
  destroy: payload => axios.delete(`${endpoint}/delete/${payload}`),
  queryFinances: payload => axios.post(`${endpoint}/query-finances`, payload),
  bestSellingProducts: payload => axios.post(endpointBestSelling, { page: payload.page, date: payload.date, perPage: payload.perPage }),
  barMechanicsFinances: payload => axios.post(`${endpoint}/stats-bar-mechanics`, payload),
  printListInvoices: payload => axios.post(`${endpoint}/print-results-invoices`, payload),
}
