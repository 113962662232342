import axios from 'axios'

const endpoint = '/api/orders'

export default {
  getAll: payload => axios.post(endpoint, { page: payload.page, paginate: payload.paginate, word: payload.word }, { headers: { 'X-Page-Size': payload.perPage } }),
  get: payload => axios.get(`${endpoint}/${payload}`),
  sendEmail: payload => axios.get(`${endpoint}/send-email/${payload}`),
  save: payload => axios.post(`${endpoint}/save`, payload),
  changeProvider: payload => axios.post(`${endpoint}/change-provider`, payload),
  cancel: payload => axios.get(`${endpoint}/cancel/${payload}`),
  deleteProduct: (payload, payload2) => axios.get(`${endpoint}/delete-product/${payload}/${payload2}`),
}
