export default [
  {
    path: '/products/products',
    name: 'drs-products',
    component: () => import('@/views/drs/products/Products.vue'),
    meta: {
      pageTitle: 'menu.products',
      breadcrumb: [
        {
          text: 'menu.products',
          to: '/products/products',
        },
        {
          text: 'common.list',
          active: true,
        },
      ],
    },
  },
  {
    path: '/products/products/:id',
    name: 'new-product',
    component: () => import('@/views/drs/products/components/ProductsForm.vue'),
    meta: {
      pageTitle: 'menu.products',
      breadcrumb: [
        {
          text: 'menu.products',
          to: '/products/products',
        },
        {
          text: 'common.form',
          active: true,
        },
      ],
    },
  },
  {
    path: '/products/products/:id#1',
    name: 'tab-product-stock',
    component: () => import('@/views/drs/products/components/ProductsForm.vue'),
    meta: {
      pageTitle: 'menu.products',
      breadcrumb: [
        {
          text: 'menu.products',
          to: '/products/products',
        },
        {
          text: 'common.form',
          active: true,
        },
      ],
    },
  },
  {
    path: '/products/stocks',
    name: 'drs-stocks',
    component: () => import('@/views/drs/products/Stocks.vue'),
    meta: {
      pageTitle: 'menu.stocks',
      breadcrumb: [
        {
          text: 'menu.stocks',
          to: '/products/stocks',
        },
        {
          text: 'common.list',
          active: true,
        },
      ],
    },
  },
  {
    path: '/products/categories',
    name: 'drs-categories',
    component: () => import('@/views/drs/products/Categories.vue'),
    meta: {
      pageTitle: 'menu.categories',
      breadcrumb: [
        {
          text: 'menu.categories',
          to: '/products/categories',
        },
        {
          text: 'common.list',
          active: true,
        },
      ],
    },
  },
  {
    path: '/products/providers',
    name: 'drs-providers',
    component: () => import('@/views/drs/products/Providers.vue'),
    meta: {
      pageTitle: 'menu.providers',
      breadcrumb: [
        {
          text: 'menu.providers',
          to: '/products/providers',
        },
        {
          text: 'common.list',
          active: true,
        },
      ],
    },
  },
  {
    path: '/products/brands',
    name: 'drs-brands',
    component: () => import('@/views/drs/products/Brands.vue'),
    meta: {
      pageTitle: 'menu.brands',
      breadcrumb: [
        {
          text: 'menu.brands',
          to: '/products/brands',
        },
        {
          text: 'common.list',
          active: true,
        },
      ],
    },
  },
]
