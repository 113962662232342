import axios from 'axios'

const endpoint = '/api/agenda'

export default {
  getById: payload => axios.get(`${endpoint}/${payload}`),
  get: payload => new Promise((resolve, reject) => {
    axios.post(endpoint, payload)
      .then(response => resolve(response))
      .catch(error => reject(error))
  }),
  create: payload => axios.post(`${endpoint}/save`, payload),
  update: payload => axios.post(`${endpoint}/save`, payload),
  delete: payload => axios.delete(`${endpoint}/${payload}`),
  sendAppointment: payload => axios.get(`${endpoint}/send-appointment/${payload}`),
  sendAppointmentCancelled: payload => axios.get(`${endpoint}/send-appointment-cancelled/${payload}`),
}
