import axios from 'axios'

const endpoint = '/api/stocks'

export default {
  get: payload => axios.post(endpoint, { page: payload.page, paginate: payload.paginate, filters: payload.filters }, { headers: { 'X-Page-Size': payload.perPage } }),
  set: payload => axios.post(`${endpoint}/save`, payload),
  delete: payload => axios.delete(`${endpoint}/${payload}`),
  getStocksByProduct: payload => axios.get(`${endpoint}/products/${payload}`),
}
