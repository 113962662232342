import axios from 'axios'

const endpoint = '/example'

export default {
  getAll: () => axios.get(endpoint),
  get: payload => axios.get(endpoint, payload),
  create: payload => axios.post(endpoint, payload),
  update: payload => axios.put(endpoint, payload),
  delete: payload => axios.delete(endpoint, payload),
}
