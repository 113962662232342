// const urls = {
//   dev: 'https://drs-dev.herokuapp.com/',
//   local: 'http://localhost:8000/',
//   prod: 'https://apidrs.ml/',
//   staging: 'https://ancient-wave-98045.herokuapp.com/',
// }

export default {
  baseURL: process.env.VUE_APP_BACKEND_URL,
}
