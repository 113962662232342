export default [
  {
    path: '/orders',
    name: 'drs-orders',
    component: () => import('@/views/drs/orders/Orders.vue'),
    meta: {
      pageTitle: 'menu.orders',
    },
  },
]
