import example from '@/api/modules/@example'
import product from '@/api/modules/product'
import brand from '@/api/modules/brand'
import category from '@/api/modules/category'
import provider from '@/api/modules/provider'
import system from '@/api/modules/system'
import user from '@/api/modules/user'
import stock from '@/api/modules/stock'
import invoice from '@/api/modules/invoice'
import customer from '@/api/modules/customer'
import log from '@/api/modules/log'
import agenda from '@/api/modules/agenda'
import budget from '@/api/modules/budget'
import auth from '@/api/modules/auth'
import orders from '@/api/modules/orders'
import stats from '@/api/modules/stats'
import finances from '@/api/modules/finances'

export default {
  example,
  product,
  finances,
  system,
  brand,
  category,
  provider,
  user,
  stock,
  log,
  invoice,
  customer,
  agenda,
  budget,
  auth,
  orders,
  stats,
}
