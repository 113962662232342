export default [
  {
    path: '/finances',
    name: 'drs-finances',
    component: () => import('@/views/drs/finance/Finances.vue'),
    meta: {
      pageTitle: 'menu.finances',
    },
  },
]
