export default [
  {
    path: '/customers/customers',
    name: 'drs-customers',
    component: () => import('@/views/drs/customers/Customers.vue'),
    meta: {
      pageTitle: 'menu.customers',
      breadcrumb: [
        {
          text: 'menu.customers',
          to: '/customers/customers',
        },
        {
          text: 'common.list',
          active: true,
        },
      ],
    },
  },
  {
    path: '/customers/agenda',
    name: 'drs-agenda',
    component: () => import('@/views/drs/customers/agenda/Agenda.vue'),
    meta: {
      pageTitle: 'menu.agenda',
      breadcrumb: [
        {
          text: 'menu.agenda',
          to: '/customers/agenda',
        },
        {
          text: 'common.calendar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/customers/customers/:id',
    name: 'new-customer',
    component: () => import('@/views/drs/customers/components/CustomersForm.vue'),
    meta: {
      pageTitle: 'menu.customers',
      breadcrumb: [
        {
          text: 'menu.customers',
          to: '/customers/customers',
        },
        {
          text: 'common.form',
          active: true,
        },
      ],
    },
  },
]
