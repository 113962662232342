export default [
  {
    path: '/users/finances',
    name: 'drs-finances',
    component: () => import('@/views/drs/finance/Finances.vue'),
    meta: {
      pageTitle: 'menu.finances',
      breadcrumb: [
        {
          text: 'menu.finances',
        },
        {
          text: 'common.list',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/activity',
    name: 'drs-logs',
    component: () => import('@/views/drs/users/Logs.vue'),
    meta: {
      pageTitle: 'menu.activity',
      breadcrumb: [
        {
          text: 'menu.activity',
        },
        {
          text: 'common.list',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/users',
    name: 'drs-users',
    component: () => import('@/views/drs/users/Users.vue'),
    meta: {
      pageTitle: 'menu.users',
      breadcrumb: [
        {
          text: 'menu.users',
          to: '/users/users',
        },
        {
          text: 'common.list',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/users/:id',
    name: 'drs-new-user',
    component: () => import('@/views/drs/users/UserForm.vue'),
    meta: {
      pageTitle: 'menu.users',
      breadcrumb: [
        {
          text: 'menu.users',
          to: '/users/users',
        },
        {
          text: 'common.form',
          active: true,
        },
      ],
    },
  },
  {
    path: '/confirm-account/set-password',
    name: 'auth-confirm-account',
    component: () => import('@/views/drs/auth/ConfirmAccount.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/drs/auth/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
]
