import axios from 'axios'

const endpoint = '/api/stats'

export default {
  statsBudgetsMechanics: payload => axios.post(`${endpoint}/stats-budgets`, { start_date: payload.start_date, end_date: payload.end_date, status: payload.status }, { headers: { 'X-Page-Size': payload.perPage } }),
  get: payload => axios.get(`${endpoint}/${payload}`),
  save: payload => axios.post(`${endpoint}/save`, payload),
  destroy: payload => axios.delete(`${endpoint}/delete/${payload}`),
}
